import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { NavigationService } from '../navigation-service/navigation.service';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {

    protected appSettingsBS = new BehaviorSubject<AppSettings | null>(null);
    public appSettingsChanged = this.appSettingsBS.asObservable();
    public get appSettings(): AppSettings | null { return this.appSettingsBS.getValue(); }

    private monitorSubscription: Subscription;

    private returnUrl: string | null;

    showMaintenanceMessage: boolean;

    constructor(private router: Router) {
    }

    public async getSettings(): Promise<AppSettings> {
        return new Promise<AppSettings>((resolve, reject) => {
            var xhttp = new XMLHttpRequest();
            const self = this;
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        self.appSettingsBS.next(JSON.parse(this.responseText));
                        resolve(self.appSettingsBS.value!);
                    } else {
                        reject();
                    }
                }
            };
            xhttp.open("GET", "assets/app.settings.json", true);
            xhttp.send();
        });
    }

    public startMonitoring(frequency = 60) {

        this.monitorSubscription = interval(frequency * 1000)
            .subscribe(async () => {
                try {
                    const previousSettings = this.appSettings;
                    const newSettings = await this.getSettings();
                    this.appSettingsBS.next(newSettings);
                    this.checkMaintenance(newSettings, previousSettings);
                } catch { }
            });
    }

    public checkMaintenance(newSettings: AppSettings, previousSettings: AppSettings | null = null) {
        // Site under maintenance?
        if (newSettings.Maintenance) {
            if (!window.location.pathname.endsWith("/maintenance")) {
                this.returnUrl = this.router.url;
                this.router.navigate(NavigationService.MiscRoutes.Maintenance());
            }
        }
        else {
            // Return from maintenance view?
            if (window.location.pathname.endsWith("/maintenance")) {
                this.router.navigateByUrl(this.returnUrl || "/");
                this.returnUrl = null;
            }

            // Revision changed?   
            if (previousSettings != null && newSettings.GitRevision != previousSettings.GitRevision) {
                window.location.reload();
            }
        }
    }

    public stopMonitoring() {
        if (this.monitorSubscription && !this.monitorSubscription.closed)
            this.monitorSubscription.unsubscribe();
    }
}

export interface AppSettings {
    InstanceId: string;
    Environment: string;
    AwsRegion: string;
    GitRevision: string;
    MainApiBaseUrl: string;
    SentryDsn: string | null;
    Maintenance?: boolean;
    MaintenanceMessage?: string;
    AssetsBaseUrl: string;
}