import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import * as Bowser from 'bowser';

@Directive({
    selector: '[autofill-disabled]'
})
export class AutofillDisabledDirective implements OnInit {

    @Input('autofill-disabled') name: string = "name";

    ngOnInit() {
        // disable autofill, with specific value for chrome
        // Not used for now cause of Chrome specific behaviour causing issues
        // const browser = Bowser.getParser(window.navigator.userAgent);
        // this.autocompleteValue = browser.getBrowserName(true) === 'chrome' ? `new-${this.name}` : 'off';
        this.autocompleteValue = "off";
    }

    @HostBinding('autocomplete') autocompleteValue: string;
}