import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdminUserResponseDTO } from '../models/admin-user/responses/AdminUserResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';

@Injectable({
    providedIn: 'root'
})
export class AdminUserAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    private listUsersSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<AdminUserResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<AdminUserResponseDTO>>(`/admin/users`, { params });
    }

    public listUsers(): Observable<AdminUserResponseDTO[]> {
        return this.getAllSegments(this.listUsersSegment.bind(this));
    }

    public disable2FA(user_id: string): Observable<void> {
        return this.httpPost<void>(`/admin/users/${user_id}/2fa/disable`, undefined);
    }
    
    public deleteUser(user_id: string): Observable<void> {
        return this.httpDelete(`/admin/users/${user_id}`);
    }
}
