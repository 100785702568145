import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UiCustomizationService } from '../ui-customization/ui-customization.service';

@Injectable({
    providedIn: 'root'
})
export class ColorSchemeService {

    public colorSchemeMode: ColorSchemeMode;

    private currentColorSchemeChangedBS = new BehaviorSubject<ColorScheme>(ColorScheme.LIGHT);
    public currentColorSchemeChanged = this.currentColorSchemeChangedBS.asObservable();

    public set currentColorScheme(value: ColorScheme) {
        this.currentColorSchemeChangedBS.next(value);
    }

    constructor(private uiCustomizationService: UiCustomizationService) {
        let colorSchemeMode: ColorSchemeMode | undefined = this.uiCustomizationService.get<ColorSchemeMode>('COLORSCHEME');
        if (!colorSchemeMode) {
            // initialize default color scheme mode to System value
            this.updateColorScheme(ColorSchemeMode.SYSTEM)
        } else {
            this.colorSchemeMode = colorSchemeMode;
            this.currentColorScheme = this.getColorSheme();
            this.applyColorScheme();
        }

        // To detect dark / light / changing from OS settings

        let mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        if ('addEventListener' in mediaQuery) {
            mediaQuery.addEventListener('change', () => {
                // If current colorScheme is System, apply new color scheme
                if (this.colorSchemeMode === ColorSchemeMode.SYSTEM) this.applyColorScheme();
            });
        }
    }

    updateColorScheme(colorSchemeMode: ColorSchemeMode) {
        this.uiCustomizationService.set('COLORSCHEME', colorSchemeMode);
        this.colorSchemeMode = colorSchemeMode;
        this.currentColorScheme = this.getColorSheme();
        this.applyColorScheme();
    }

    getColorSheme(): ColorScheme {
        let colorScheme: ColorScheme = ColorScheme.LIGHT;

        if (this.colorSchemeMode === ColorSchemeMode.SYSTEM) {
            let systemColorScheme: string = matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";
            colorScheme = (<any>ColorScheme)[systemColorScheme.toUpperCase()];
        } else {
            if (this.colorSchemeMode === ColorSchemeMode.USER_DARK) colorScheme = ColorScheme.DARK;
            else if (this.colorSchemeMode === ColorSchemeMode.USER_LIGHT) colorScheme = ColorScheme.LIGHT;
        }
        return colorScheme;
    }

    applyColorScheme() {
        // clean old color scheme value then apply new
        document.documentElement.classList.remove('dark', 'light');
        document.documentElement.classList.add(this.getColorSheme().toLowerCase())
    }
}

export enum ColorSchemeMode {
    USER_DARK = "USER_DARK",
    USER_LIGHT = "USER_LIGHT",
    SYSTEM = "SYSTEM"
}

export enum ColorScheme {
    DARK = "DARK",
    LIGHT = "LIGHT"
}
