import { EventPublicationStatus } from "projects/api-client/src/models/common/EventPublicationStatus";
import { LocalizedKeyString } from "projects/api-client/src/models/common/LocalizedKeyString";
import { EventMainSettingsCommandDTO } from "projects/api-client/src/models/event/commands/EventMainSettingsCommandDTO";
import { AssetResponseDTO } from "projects/api-client/src/models/event/responses/EventAdmin/AssetResponseDTO";
import { EventAdminResponseDTO } from "projects/api-client/src/models/event/responses/EventAdminResponseDTO";
import { Address } from "../common/address";
import { LocalDate } from "projects/api-client/src/types/LocalDate";

export class EventAdmin {

    id: string;
    name: LocalizedKeyString;
    start_date: LocalDate = new LocalDate();
    end_date: LocalDate = new LocalDate();
    time_zone: string;
    address?: Address;
    currency: string;
    logo_asset_key?: string;
    poster_asset_key?: string;
    carousel_images: AssetResponseDTO[] = [];
    sms_header_override?: string;
    applications_open: boolean;
    is_archived: boolean;
    automatic_archival_date: Date;
    publication_status: EventPublicationStatus;
    is_verified: boolean;
    recruitment_started: boolean;
    has_any_activity: boolean;
    teammates_count: number;
    unread_messages_count: number;
    assigned_assignments_count: number;
    pre_registrations_count: number;
    licenses_quota: number;
    licenses_consumed: number;
    recruitment_target: number;
    enable_licenses: boolean;
    authorize_all_activity_assignments_by_default: boolean;
    available_skills: LocalizedKeyString[] = [];
    available_language_skills: LocalizedKeyString[] = [];
    available_diets: LocalizedKeyString[] = [];
    enable_teammate_friend_group: boolean;
    enable_teammate_meal_informations: boolean;
    enable_teammate_information_map: boolean;
    organization_id: string;
    organization_name: string;
    organization_sms_quota: number;
    created_at: Date;
    updated_at: Date;
    
    static from(dto: EventAdminResponseDTO) {
        const event = new EventAdmin();
        event.id = dto.id;
        event.name = dto.name;
        event.start_date = LocalDate.from(dto.start_date);
        event.end_date = LocalDate.from(dto.end_date);
        event.time_zone = dto.time_zone;
        event.address = new Address(dto.address);
        event.currency = dto.currency;
        event.logo_asset_key = dto.logo_asset_key;
        event.poster_asset_key = dto.poster_asset_key;
        event.carousel_images = dto.carousel_images;
        event.sms_header_override = dto.sms_header_override;
        event.applications_open = dto.applications_open;
        event.is_archived = dto.is_archived;
        event.automatic_archival_date = dto.automatic_archival_date;
        event.publication_status = dto.publication_status;
        event.is_verified = dto.is_verified;
        event.recruitment_started = dto.recruitment_started;
        event.has_any_activity = dto.has_any_activity;
        event.teammates_count = dto.teammates_count;
        event.unread_messages_count = dto.unread_messages_count;
        event.assigned_assignments_count = dto.assigned_assignments_count;
        event.pre_registrations_count = dto.pre_registrations_count;
        event.licenses_quota = dto.licenses_quota;
        event.licenses_consumed = dto.licenses_consumed;
        event.recruitment_target = dto.recruitment_target;
        event.enable_licenses = dto.enable_licenses;
        event.authorize_all_activity_assignments_by_default = dto.authorize_all_activity_assignments_by_default;
        event.available_skills = dto.available_skills;
        event.available_language_skills = dto.available_language_skills;
        event.available_diets = dto.available_diets;
        event.organization_id = dto.organization_id;
        event.organization_name = dto.organization_name;
        event.organization_sms_quota = dto.organization_sms_quota;
        event.created_at = new Date(dto.created_at);
        event.updated_at = new Date(dto.updated_at);

        return event;
    }

    AsEventMainSettingsCommandDTO(): EventMainSettingsCommandDTO {
        return {
            name: this.name.key,
            start_date: this.start_date,
            end_date: this.end_date,
            time_zone: this.time_zone,
            address: this.address!,
            currency: this.currency,
            logo_asset_key: this.logo_asset_key!,
            poster_asset_key: this.poster_asset_key!,
            carousel_images: this.carousel_images.map(x => {
                return {
                    asset_key: x.key,
                    copyright: x.copyright
                }
            })
        }
    }
}
