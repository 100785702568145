import { Inject, Pipe, PipeTransform } from "@angular/core";
import { AddressDTO } from 'projects/api-client/src/models/common/AddressDTO';
import { Address } from '../models/common/address';
import { Country, VocabularyService } from '../services/vocabulary-service/vocabulary.service';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {

    countries: Country[];

    constructor(@Inject(VocabularyService) private vocabularyService: VocabularyService) {
        this.countries = this.vocabularyService.getCountries();
    }

    transform(address?: AddressDTO, format: "short"|"medium"|"full" = "short") {
        if (address) {
            return Address.getAddressAsString(address, this.countries, format);
        }
        else {
            return null;
        }
    }
}