<div class="dialog-content-wrapper pt-5">
    <div mat-dialog-content [ngClass]="{'pt-8 pb-4': data.icon != undefined }">
        <p class="text-center" *ngIf="data.icon != undefined">
            <ng-container [ngSwitch]="data.icon">
                <img class="inline max-w-210px" *ngSwitchCase="IconTypes.SUCCESS" src="assets/images/icons/ico-success.svg" alt="" />
                <img class="inline max-w-210px" *ngSwitchCase="IconTypes.WAIT" src="assets/images/icons/ico-waiting.svg" alt="" />
                <img class="inline max-w-210px" *ngSwitchCase="IconTypes.INFO" src="assets/images/icons/ico-info.svg" alt="" />
                <img class="inline max-w-210px" *ngSwitchCase="IconTypes.WARNING" src="assets/images/icons/ico-warning.svg" alt="" />
                <img class="inline max-w-210px" *ngSwitchCase="IconTypes.ERROR" src="assets/images/icons/ico-error.svg" alt="" />
                <img class="inline max-w-210px" *ngSwitchCase="IconTypes.CUSTOM" [src]="data.icon_url" alt="" />
            </ng-container>
        </p>

        <p class="text-xl font-medium" *ngIf="data.title" [ngClass]="{'text-center': data.icon != undefined }">{{data.title}}</p>

        <div class="mb-3" *ngIf="data.body" [innerHTML]="data.body" [ngClass]="{'text-center': data.icon != undefined }"></div>
        <div class="qo-form" *ngIf="data.type === MessageTypes.STRONG_CONFIRM">
            <mat-checkbox class="pb-2" color="primary" [(ngModel)]="strongConfirm" name="strong_confirm">{{data.strongConfirmLabel}}</mat-checkbox>
        </div>
    </div>
    <div mat-dialog-actions>
        <ng-container *ngIf="data.customButtons">
            <ng-container *ngFor="let customButton of data.customButtons" [ngSwitch]="customButton.type">
                <button *ngSwitchCase="'FLAT'" mat-flat-button [color]="customButton.color" 
                    (click)="customButton.onClick(dialogRef);dialogRef.close();">{{customButton.title}}</button>
                <button *ngSwitchCase="'STROKED'" mat-stroked-button [color]="customButton.color" 
                    (click)="customButton.onClick(dialogRef);dialogRef.close();">{{customButton.title}}</button>
                <button *ngSwitchDefault mat-button [color]="customButton.color" 
                    (click)="customButton.onClick(dialogRef);dialogRef.close();">{{customButton.title}}</button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!data.customButtons">
            <button mat-flat-button color="primary" (click)="dialogRef.close(true)" aria-label="OK" 
                [disabled]="data.type === MessageTypes.STRONG_CONFIRM && !strongConfirm" i18n>OK</button>
            <button *ngIf="data.type !== MessageTypes.INFO" mat-button (click)="dialogRef.close(false)" aria-label="Cancel" i18n>Cancel</button>
        </ng-container>
    </div>
</div>