export enum Currency {
    EUR = "EUR",
    GBP = "GBP",
    CHF = "CHF",
    USD = "USD",   
    AUD = "AUD",    
    CAD = "CAD",
    BRL = "BRL",
    JPY = "JPY",
    CNY = "CNY"
}


