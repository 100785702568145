import { Component, OnDestroy, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IsLoadingService } from 'src/app/shared/services/is-loading/is-loading.service';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MessageDialogComponent, MessageTypes } from 'src/app/shared/components/message-dialog/message-dialog.component';
import { SmsOrderingService } from 'src/app/shared/services/sms-ordering-service/sms-ordering-service';
import { OrganizationAdmin } from '../../models/organization/organization-admin';
import { OrganizationSettingsLicenseAPIService } from 'projects/api-client/src/api/organization-settings-license.service';
import { MatomoService } from '../../services/matomo-service/matomo-service';
import { Currency } from '../../models/common/currency';
import { CustomAddressDialogComponent, CustomAddressDialogResult } from '../custom-address-dialog/custom-address-dialog.component';
import { ApplicationSettingsService } from '../../services/application-settings-service/application-settings.service';

@Component({
    templateUrl: './order-sms-dialog.component.html',
    styleUrls: ['./order-sms-dialog.component.scss']
})
export class OrderSmsDialogComponent implements OnDestroy {

    SmsOrderingService = SmsOrderingService;
    Currency = Currency;
    Steps = Steps;
    currentStep = Steps.SmsSettings;

    smsOrderingService: SmsOrderingService;
    organization: OrganizationAdmin;

    formGroupBillingDetails = new UntypedFormGroup({
        vatNumber: new UntypedFormControl(),
        billingAddress: new UntypedFormControl(null, Validators.required)
    });

    paymentInfos: {
        paymentFormToken?: string,
        amountExcludingTaxes?: number,
        amountIncludingTaxes?: number
    } = {};

    constructor(@Inject(MAT_DIALOG_DATA) data: OrderSmsDialogData,
        private dialogRef: MatDialogRef<OrderSmsDialogComponent>,
        private dialog: MatDialog,
        applicationSettingsService: ApplicationSettingsService,
        private organizationLicenseAPIService: OrganizationSettingsLicenseAPIService,
        public isLoadingService: IsLoadingService,
        private matomoService: MatomoService,
        public router: Router) {
        this.organization = data.organization;
        this.smsOrderingService = new SmsOrderingService(applicationSettingsService.settings, this.organization.sms_quota);
        this.smsOrderingService.additionalSmsNumber = SmsOrderingService.STEP;
        this.formGroupBillingDetails.setValue({
            vatNumber: this.organization.vat_number || null,
            billingAddress: this.organization.billing_address || null
        });
    }

    async applySmsSettings() {
        // Update billing details and SMS quota
        const updateLicensesResponse = await this.organizationLicenseAPIService.update(this.organization.id, {
            billing_address: this.formGroupBillingDetails.value.billingAddress,
            vat_number: this.formGroupBillingDetails.value.vatNumber,
            coupon_codes: [],
            licenses_ordered: 0,
            sms_quota_ordered: this.smsOrderingService.smsToPay
        }).toPromise();

        this.paymentInfos = {
            paymentFormToken: updateLicensesResponse.payment_form_token,
            amountExcludingTaxes: updateLicensesResponse.amount_excluding_taxes,
            amountIncludingTaxes: updateLicensesResponse.amount_including_taxes
        };

        if (this.paymentInfos.paymentFormToken) {
            this.currentStep = Steps.Payment;
        } else {
            this.dialogRef.close();
        }
    }

    async onPaymentSucceeded() {
        this.currentStep = Steps.PaymentSucceeded;
        this.matomoService.trackEvent("Payment", "SMS", "Completed", this.paymentInfos.amountIncludingTaxes);
    }

    closeWithConfirmation() {
        if ([Steps.SmsSettings, Steps.Payment].includes(this.currentStep)) {
            this.dialog.open(MessageDialogComponent, {
                data: {
                    body: $localize`Are you sure you want to stop adding SMS?`,
                    type: MessageTypes.CONFIRM
                },
                panelClass: 'message-dialog'
            }).afterClosed().subscribe(async confirmed => {
                if (confirmed) {
                    this.dialogRef.close();
                }
            });
        } else {
            this.dialogRef.close();
        }
    }
    
    openCustomAddressDialog() {
        this.isLoadingService.add({ key: "providingCustomAddress" });
        this.dialog.open(CustomAddressDialogComponent, {
            autoFocus: true
        }).afterClosed().subscribe(async (result?: CustomAddressDialogResult) => {
            if (result && result.address) {
                this.formGroupBillingDetails.controls.billingAddress.setValue(result.address);
                this.formGroupBillingDetails.markAsDirty();
            }
            this.isLoadingService.remove({ key: "providingCustomAddress" });
        });
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}

enum Steps {
    SmsSettings,
    Payment,
    PaymentSucceeded,
    PaymentFailed
}

export interface OrderSmsDialogData {
    organization: OrganizationAdmin;
}
