import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent, ImageDialogData } from './image-dialog.component';

@Directive({
    selector: '[lightbox]'
})
export class ImageDialogDirective {

    @Input('lightbox') imageUrl: string;

    constructor(private dialog: MatDialog) {
    }

    @HostListener('click')
    onClick() {
        const data: ImageDialogData = {
            imageUrl: this.imageUrl
        }
        this.dialog.open(ImageDialogComponent, { data });
    }
}
