import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateAssignmentCommandDTO } from "../models/assignment/commands/CreateAssignmentCommandDTO";
import { AssignmentResponseDTO } from '../models/assignment/responses/AssignmentResponseDTO';
import { UpdateAssignmentStatusCommandDTO } from '../models/assignment/commands/UpdateAssignmentStatusCommandDTO';
import { TeammateAssignmentResponseDTO } from '../models/assignment/responses/TeammateAssignmentResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { UpdateAttendanceStatusCommandDTO } from '../models/assignment/commands/UpdateAttendanceStatusCommandDTO';
import { AssignmentGeneratorCommandDTO } from '../models/assignment/commands/AssignmentGeneratorCommandDTO';
import { AssignmentGeneratorResponseDTO } from '../models/assignment/responses/AssignmentGeneratorResponseDTO';
import { AssignmentDetailsResponseDTO } from '../models/assignment/responses/AssignmentDetailsResponseDTO';
import { BulkUpdateAssignmentStatusCommandDTO } from '../models/assignment/commands/BulkUpdateAssignmentStatusCommandDTO';
import { BulkDeleteAssignmentCommandDTO } from '../models/assignment/commands/BulkDeleteAssignmentCommandDTO';
import { BulkCreateAssignmentsCommandDTO } from '../models/assignment/commands/BulkCreateAssignmentCommandDTO';
import { TeammateRequirementsResponseDTO } from '../models/assignment/responses/TeammateRequirementsResponseDTO';
import { TeammateRequirementsCommandDTO } from '../models/assignment/commands/TeammateRequirementsCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class AssignmentAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Creates a new assignment between a shift and a teammate.
     * @param event_id id of the event
     * @param body The command used to initialize the new assignment.
     */
    public create(event_id: string, body: CreateAssignmentCommandDTO): Observable<AssignmentResponseDTO> {
        return this.httpPost<AssignmentResponseDTO>(`/events/${event_id}/assignments`, body);
    }
    
    /**
     * Creates a new set of assignments between the specified shifts and teammates.
     * @param event_id id of the event
     * @param body The command used to initialize the new assignments.
     */
    public bulkCreate(event_id: string, body: BulkCreateAssignmentsCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/assignments/bulk-create`, body);
    }
    
    /**
     * Updates the status of an existing assignment.
     * @param id id of the assignment
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public updateStatus(id: string, event_id: string, body: UpdateAssignmentStatusCommandDTO): Observable<AssignmentResponseDTO> {
        return this.httpPut<AssignmentResponseDTO>(`/events/${event_id}/assignments/${id}/status`, body);
    }

    /**
     * Updates the attendance status of an existing assignment.
     * @param id id of the assignment
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public updateAttendanceStatus(id: string, event_id: string, body: UpdateAttendanceStatusCommandDTO): Observable<AssignmentResponseDTO> {
         return this.httpPut<AssignmentResponseDTO>(`/events/${event_id}/assignments/${id}/attendance-status`, body);
    }

    /**
     * Deletes the specified assignment.
     * @param id id of the assignment
     * @param event_id id of the event
     */
    public delete(id: string, event_id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/assignments/${id}`);
    }

    private mineSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<TeammateAssignmentResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<TeammateAssignmentResponseDTO>>(`/events/${event_id}/assignments/mine`, { params });
    }

    /**
     * Lists all current user's assignments.
     *
     * @param event_id id of the event
     */
    public mine(event_id: string): Observable<TeammateAssignmentResponseDTO[]> {
        return this.getAllSegments(this.mineSegment.bind(this), event_id);
    }

    public generator(event_id: string, body: AssignmentGeneratorCommandDTO): Observable<AssignmentGeneratorResponseDTO> {
        return this.httpPost<AssignmentGeneratorResponseDTO>(`/events/${event_id}/assignments/generator`, body);
    }

    public estimateTeammateRequirements(event_id: string, body: TeammateRequirementsCommandDTO): Observable<TeammateRequirementsResponseDTO> {
        return this.httpPost<TeammateRequirementsResponseDTO>(`/events/${event_id}/assignments/teammate-requirements`, body);
    }

    public getDetails(id: string, event_id: string, teammate_id: string): Observable<AssignmentDetailsResponseDTO> {
        return this.httpGet<AssignmentDetailsResponseDTO>(`/events/${event_id}/assignments/details?shift_id=${id}&teammate_id=${teammate_id}`)
    }

    /**
     * Get the list of teammates.
     * @param event_id id of the event
     * @param params The httpParams.
     */
    private listAssignmentsSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<AssignmentResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<AssignmentResponseDTO>>(`/events/${event_id}/assignments`, { params });
    }

    public listAssignments(event_id: string): Observable<AssignmentResponseDTO[]> {
        return this.getAllSegments(this.listAssignmentsSegment.bind(this), event_id);
    }

    public bulkUpdateStatus(event_id: string, body: BulkUpdateAssignmentStatusCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/assignments/status/bulk`, body);
    }
    
     /**
     * Deletes a set of assignments.
     * @param event_id id of the event
     * @param body The command used to delete the assignments.
     */
    public bulkDelete(event_id: string, body: BulkDeleteAssignmentCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/assignments/bulk-delete`, body);
    }
}
