import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { EventServiceResponseDTO } from '../models/event-service/responses/EventServiceResponseDTO';
import { EventServiceUpdateCommandDTO } from '../models/event-service/commands/EventServiceUpdateCommand';
import { ServiceCode } from '../models/common/ServiceCode';
import { WeezeventEventServiceUpdateCommandDTO } from '../models/event-service/commands/WeezeventEventServiceUpdateCommandDTO';
import { WeezeventEventServiceResponseDTO } from '../models/event-service/responses/WeezeventEventServiceResponseDTO';
import { TeammateEventServiceResponseDTO } from '../models/event-service/responses/TeammateEventServiceResponseDTO';
import { QomeetEventServiceResponseDTO } from '../models/event-service/responses/QomeetEventServiceResponseDTO';
import { QomeetEventServiceUpdateCommandDTO } from '../models/event-service/commands/QomeetEventServiceUpdateCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class EventServiceAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }
    
    public list(event_id: string): Observable<EventServiceResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), event_id);
    }

    private listSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<EventServiceResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventServiceResponseDTO>>(`/events/${event_id}/services/available`, { params });
    }
    
    public getTeammateServices(event_id: string): Observable<TeammateEventServiceResponseDTO[]> {
        return this.getAllSegments(this.getTeammateServicesSegment.bind(this), event_id);
    }

    private getTeammateServicesSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<TeammateEventServiceResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<TeammateEventServiceResponseDTO>>(`/events/${event_id}/services/teammate`, { params });
    }

    public update(event_id: string, code: ServiceCode, body: EventServiceUpdateCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/services/${code}`, body);
    }

    public get(event_id: string, code: ServiceCode): Observable<EventServiceResponseDTO> {
        return this.httpGet<EventServiceResponseDTO>(`/events/${event_id}/services/${code}`);
    }

    public updateWeezevent(event_id: string, body: WeezeventEventServiceUpdateCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/services/WEEZEVENT`, body);
    }

    public getWeezevent(event_id: string): Observable<WeezeventEventServiceResponseDTO> {
        return this.httpGet<WeezeventEventServiceResponseDTO>(`/events/${event_id}/services/WEEZEVENT`);
    }

    public updateQomeet(event_id: string, body: QomeetEventServiceUpdateCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/services/QOMEET`, body);
    }

    public getQomeet(event_id: string): Observable<QomeetEventServiceResponseDTO> {
        return this.httpGet<QomeetEventServiceResponseDTO>(`/events/${event_id}/services/QOMEET`);
    }
}
