import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdminPaymentResponseDTO } from '../models/admin-payment/responses/AdminPaymentResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';

@Injectable({
    providedIn: 'root'
})
export class AdminPaymentAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }
 
    private listPaymentsSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<AdminPaymentResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<AdminPaymentResponseDTO>>(`/admin/payments`, { params });
    }

    /**
     * Lists all payments on the platform.
     */
    public listPayments(): Observable<AdminPaymentResponseDTO[]> {
        return this.getAllSegments(this.listPaymentsSegment.bind(this));
    }

    /**
     * Deletes the specified payment.
     * @param payment_id id of the payment
     */
    public deletePayment(payment_id: string): Observable<void> {
        return this.httpDelete(`/admin/payments/${payment_id}`);
    }
}
