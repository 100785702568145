<div class="flex flex-col h-[80%] px-6">
    <header class="flex items-center bg-white dark:bg-qo-dark-700 shrink-0 h-14" role="banner">
        <a [routerLink]="NavigationService.AccountRoutes.Profile()">
            <img class="max-w-100px max-h-16" *ngIf="currentColorScheme === ColorScheme.LIGHT" src="assets-custom/images/logo.svg" alt="" />
            <img class="max-w-100px max-h-16" *ngIf="currentColorScheme === ColorScheme.DARK" src="assets-custom/images/logo-n.svg" alt="" />  
        </a>
    </header>

    <main class="flex flex-1 py-4 max-w-3xl mx-auto" role="main">
        <div class="m-auto text-center">
            <h1 class="qo-h1 mb-8" i18n>Your account has been deleted.</h1>
            <p class="qo-h3 mb-10" i18n>We sincerely thank you for your investment.</p>
            <p><a [routerLink]="NavigationService.AccountRoutes.Profile()" mat-stroked-button i18n>Return to home page</a></p>
        </div>
    </main>
</div>