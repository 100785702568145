import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Address } from '../../models/common/address';
import { AddressPipe } from '../../pipes/address';
import { GeolocationService, PLACE_SEARCH_SCOPE } from '../../services/geolocation-service/geolocation.service';
import { VocabularyService } from '../../services/vocabulary-service/vocabulary.service';

@Component({
    selector: 'address-input',
    templateUrl: './address-input.component.html',
    styleUrls: ['./address-input.component.scss'],
    providers: [{
        provide: MatFormFieldControl,
        useExisting: AddressInputComponent
    }],
})
export class AddressInputComponent implements OnInit, MatFormFieldControl<Address | undefined>, ControlValueAccessor {
    @ViewChild('searchInput') searchInput!: HTMLInputElement;

    @Input() placeSearchScope: PLACE_SEARCH_SCOPE | undefined = PLACE_SEARCH_SCOPE.FULL_ADDRESS;
    @Input() allowCustomAddress: boolean;
    showCustomAddressButton: boolean;

    @Output() customAddressClicked = new EventEmitter<void>();

    address: Address | undefined;
    currentValue: string;
    locationPredictions: AddressPrediction[] = [];

    searchLocationCtrl = new UntypedFormControl("");
    addressPipeFormat:"short" | "medium" | "full";

    constructor(
        private vocabularyService: VocabularyService,
        private elRef: ElementRef<HTMLElement>,
        private _focusMonitor: FocusMonitor,
        private locationService: GeolocationService,
        @Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) { this.ngControl.valueAccessor = this; }
    }
    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    setDescribedByIds(ids: string[]): void { }
    
    onContainerClick() {
        this._focusMonitor.focusVia(this.searchInput, 'program');
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    controlType = "address-input";
    autofilled?: boolean;
    userAriaDescribedBy?: string | undefined;

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        if (this.searchLocationCtrl) {
            this._disabled = coerceBooleanProperty(value);
            this._disabled ? this.searchLocationCtrl.disable() : this.searchLocationCtrl.enable();
        }
    }
    private _disabled = false;

    stateChanges = new Subject<void>();

    ngOnInit(): void {
        this.addressPipeFormat = this.placeSearchScope == PLACE_SEARCH_SCOPE.CITIES ? "short" : "full";
        // Refresh predictions when input value changes
        this.searchLocationCtrl.valueChanges
            .pipe(
                takeUntil(this.$destroy),
                debounceTime(200))
            .subscribe(value => {
                this.locationPredictions = [];
                this.ngControl.control?.setErrors(null);
                this.showCustomAddressButton = false;
                if (value) {
                    if (!this.address && value.length >= 3) {
                        this.showCustomAddressButton = true;
                        this.loadPredictions(value.trim());
                    }
                    else this.showCustomAddressButton = false;
                } else if (this.required) this.ngControl.control?.setErrors({ required: 1 })
                this.stateChanges.next();
            });
    }

    public async setSelectedPrediction(selectedPrediction: AddressPrediction) {
        if (selectedPrediction) {
            // Get prediction details from service when selected
            this.address = await this.locationService.getPredictionDetails(selectedPrediction.place_id);
            this.searchLocationCtrl.setValue(this.address != null ? new AddressPipe(this.vocabularyService).transform(this.address, this.addressPipeFormat) : "");
            this.onChange(this.address);
        }
    }

    async loadPredictions(query: string) {
        try {
            this.locationPredictions = await this.locationService.refreshPredictions(query, [this.placeSearchScope ?? PLACE_SEARCH_SCOPE.FULL_ADDRESS]);
        }
        catch { } // silent exception
    }

    getDisplayOption = () => {
        if (this.address) {
            const stringAddress = new AddressPipe(this.vocabularyService).transform(this.address, this.addressPipeFormat);
            return stringAddress ? stringAddress.trim() : "";
        }
        return "";
    }

    static nextId = 0;
    id = `address-input-${AddressInputComponent.nextId++}`;

    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    private _placeholder: string;
    
    get empty() {
        const location = this.searchLocationCtrl.value ?? "";
        return location.length === 0 && !this.address;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input() get value(): Address | undefined {
        return this.address;
    }
    set value(value: Address | undefined) {
        this.address = value;
        this.searchLocationCtrl.setValue(value != null ? new AddressPipe(this.vocabularyService).transform(value, this.addressPipeFormat) : "");
    }

    onChange: (_: any) => void = (_: any) => { };
    onTouched: () => void = () => { };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    get errorState(): boolean {
        if (this.ngControl) {
            if (!this.touched && this.ngControl.touched) {
                this.touched = true;
                if (this.required && this.empty) {
                    this.ngControl.control?.setErrors({ required: 1 });
                    this.stateChanges.next();
                }
            }
            return !!this.ngControl.invalid && this.touched;
        }
        return false;
    };
    
    touched: boolean = false;
    focused: boolean = false;
    onFocusIn(event: FocusEvent) {
        this.focused = true;
        this.stateChanges.next();
    }
    onFocusOut(event: FocusEvent) {
        this.focused = false;
        this.touched = true;
        if (this.ngControl) {
            if (this.required && this.empty) {
                this.ngControl.control?.setErrors({ required: 1 });
            }
            else if (!this.empty && !this.address) {
                this.ngControl.control?.setErrors({ invalidAddress: 1 });
            }
        }
        this.stateChanges.next();
    }


    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}

interface AddressPrediction {
    place_id: string;
    description: string;
}
