<div class="qo-DialogFullScreen-close">
    <button mat-icon-button (click)="closeWithConfirmation()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="px-3 pb-4" [ngSwitch]="currentStep">

    <div class="mx-auto max-w-4xl" *ngSwitchCase="Steps.SmsSettings">

        <h1 class="qo-tiny-h1 mb-1 text-center" i18n>Add SMS</h1>

        <p class="flex items-center justify-center">
            <img *ngIf="organization.logo_asset_key" [src]="organization.logo_asset_key|toAssetUrl" alt="" class="rounded mr-2 w-7"/>
            {{organization.name}}
        </p>

        <div class="border border-qo-light rounded-xl mx-auto max-w-2xl p-4 md:py-6 md:px-8">

            <h2 class="qo-h3" i18n>Additionnal SMS number</h2>

            <mat-slider class="block" discrete [min]="SmsOrderingService.STEP" 
                [max]="SmsOrderingService.MAX" [step]="SmsOrderingService.STEP" #ngSlider><input matSliderThumb [value]="smsOrderingService.additionalSmsNumber" (input)="smsOrderingService.additionalSmsNumber = {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value!" #ngSliderThumb="matSliderThumb" />
            </mat-slider>
            <div class="opacity-40 flex justify-between text-sm font-medium -mt-1">
                <span>{{SmsOrderingService.STEP}}</span>
                <span>{{SmsOrderingService.MAX}}</span>
            </div>

            <p class="qo-PrimaryTexttext-xl font-bold m-0 text-center" i18n>{{(smsOrderingService.smsUnitPriceExcludingTaxes *100)|currencyNoZeroCents:Currency.EUR}} excl tax for 100 SMS</p>
            <p class="text-center" i18n>You will have a total of {{smsOrderingService.totalSmsNumber}} SMS.</p>
            
            <ng-container *ngIf="smsOrderingService.priceExcludingTaxes > 0">
                <p class="flex items-center justify-center mt-6 text-5xl/none md:text-6xl font-medium text-center" >
                    {{smsOrderingService.priceExcludingTaxes|currencyNoZeroCents:Currency.EUR}}<span class="text-xl ml-1" i18n> excl tax</span>
                </p>

                <form class="pt-4" [formGroup]="formGroupBillingDetails">
                    <h2 class="qo-h3" i18n>Your billing information</h2>
                    <mat-form-field appearance="fill" class="w-full m-0">
                        <mat-label i18n>VAT Number (optional)</mat-label>
                        <input matInput formControlName="vatNumber">
                    </mat-form-field>
                    
                    <mat-form-field appearance="fill" class="w-full m-0">
                        <mat-label i18n>Billing address</mat-label>
                        <address-input #location formControlName="billingAddress" class="w-full max-w-2xl" 
                        [allowCustomAddress]="true"
                        (customAddressClicked)="openCustomAddressDialog()"
                        [disabled]="('providingCustomAddress'|isLoading|async)" required></address-input>
                        <mat-icon *ngIf="!formGroupBillingDetails.value.billingAddress" matSuffix>search</mat-icon>
                        <button mat-icon-button *ngIf="formGroupBillingDetails.value.billingAddress" matSuffix (click)="this.formGroupBillingDetails.controls.billingAddress.setValue(undefined)">
                            <mat-icon class="material-icons-outlined" matTooltip="Delete address" i18n-matTooltip>clear</mat-icon>
                        </button>
                        <mat-error *ngIf="formGroupBillingDetails.controls.billingAddress.errors" i18n>You must provide a valid address</mat-error>
                    </mat-form-field>                    
                </form>
                <p class="m-0 text-center">
                    <button *ngIf="smsOrderingService.priceExcludingTaxes > 0"
                        mat-raised-button class="qo-big-button" color="primary" type="submit" 
                        [disabled]="!formGroupBillingDetails.valid || ('OrderSms'|isLoading|async)"
                        (click)="applySmsSettings()">
                        <ng-container i18n>Proceed to payment</ng-container>
                        <mat-spinner *ngIf="'OrderSms'|isLoading|async" diameter="24"></mat-spinner>
                    </button>
                </p>
            </ng-container>
        </div>

        <div class="my-8 text-center">
            <p class="mb-2"><strong i18n>Any questions?</strong></p>
            <p><a mat-stroked-button color="primary" href="https://quick-off.atlassian.net/servicedesk/customer/portal/6/group/6/create/52" target="_blank" i18n>Contact us</a></p>
        </div>
    </div>

    <payment-form *ngSwitchCase="Steps.Payment"
        [paymentFormToken]="paymentInfos.paymentFormToken"
        [quantity]="smsOrderingService.additionalSmsNumber"
        quantityLabel="Number of SMS" i18n-quantityLabel
        [priceIncludingTaxes]="paymentInfos.amountIncludingTaxes"
        [priceExcludingTaxes]="paymentInfos.amountExcludingTaxes"
        (paymentSucceeded)="onPaymentSucceeded()"
        (paymentFailed)="currentStep = Steps.PaymentFailed">
    </payment-form>

    <div *ngSwitchCase="Steps.PaymentSucceeded" class="mx-auto max-w-3xl py-10vh text-center">
        <p class="mb-4"><img class="inline w-full max-w-210px" src="assets/images/icons/ico-success.svg" alt="" /></p>
        <h1 class="qo-tiny-h1" i18n>Thank you for your order</h1>
        <p class="mb-6" i18n>Your payment has been validated, you can use your SMS now.</p>
        <p><button mat-raised-button class="qo-big-button" color="primary" matDialogClose i18n>Close</button></p>
    </div>

    <div *ngSwitchCase="Steps.PaymentFailed" class="mx-auto max-w-3xl py-10vh text-center">    
        <p class="mb-4"><img class="inline w-full max-w-210px" src="assets/images/icons/ico-warning.svg" alt="" /></p>
        <h1 class="qo-tiny-h1" i18n>Payment failed!</h1>
        <p class="mb-6" i18n>Your payment could not be completed. Please try again or <a href="https://quick-off.atlassian.net/servicedesk/customer/portal/4/group/4/create/43" target="_blank">contact</a>the support.</p>
        <p><button mat-raised-button class="qo-big-button" color="primary" matDialogClose i18n>Close</button></p>
    </div>
</div>