import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'currencyNoZeroCents' })
export class CurrencyNoZeroCentsPipe implements PipeTransform {

    private currencyPipe: CurrencyPipe;

    constructor(
        @Inject(LOCALE_ID) _locale: string,
        @Inject(DEFAULT_CURRENCY_CODE) _defaultCurrencyCode: string = 'USD') {
        this.currencyPipe = new CurrencyPipe(_locale, _defaultCurrencyCode);
    }

    transform(value: number | string, currencyCode?: string): string | null {

        if (value === null) return null;
        
        const nb = Number(value);
        if (nb - Math.floor(nb) === 0) {
            // Display without cents
            return this.currencyPipe.transform(value, currencyCode, "symbol", "1.0-0");
        } else {
            return this.currencyPipe.transform(value, currencyCode);
        }
    }
}