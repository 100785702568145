import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Address } from '../../models/common/address';
import { PLACE_SEARCH_SCOPE } from '../../services/geolocation-service/geolocation.service';
import { Country, VocabularyService } from '../../services/vocabulary-service/vocabulary.service';

@Component({
    selector: 'qo-custom-address-dialog',
    templateUrl: './custom-address-dialog.component.html',
    styleUrls: ['./custom-address-dialog.component.scss']
})
export class CustomAddressDialogComponent implements OnInit {

    PLACE_SEARCH_SCOPE = PLACE_SEARCH_SCOPE;

    address: Address;
    countries: Country[];

    formGroup = new UntypedFormGroup({
        line1: new UntypedFormControl(null),
        line2: new UntypedFormControl(null),
        cityAddress: new UntypedFormControl(null, Validators.required),
        postalCode: new UntypedFormControl(null),
        state: new UntypedFormControl(null),
        country: new UntypedFormControl({ value: null, disabled: true})
    });

    constructor(
        private dialogRef: MatDialogRef<CustomAddressDialogComponent>,
        vocabularyService: VocabularyService
    ) {
        this.countries = vocabularyService.getCountries();
    }

    ngOnInit(): void {
        this.formGroup.controls.cityAddress.valueChanges
            .pipe(
                takeUntil(this.$destroy),
                debounceTime(300))
            .subscribe((value: Address | undefined) => {
                if (value) {
                    const country = this.countries.find(x => x.code === value.country)?.title || value.country;
                    this.formGroup.patchValue({
                        postalCode: value.postal_code ?? null,
                        state: value.state ?? null,
                        country: country ?? null
                    });
                }
            });
    }

    clearCirtyAddress() {
        this.formGroup.patchValue(
            {
                cityAddress: null,
                postalCode: null,
                state: null,
                country: null
            }
        )

    }

    save() {
        if (this.formGroup.valid) {
            let cityAddress = this.formGroup.value.cityAddress as Address;
            this.address =
            {
                line1: this.formGroup.value.line1 ?? undefined,
                line2: this.formGroup.value.line2 ?? undefined,
                city: cityAddress.city,
                state: this.formGroup.value.state ?? undefined,
                postal_code: this.formGroup.value.postalCode  ?? undefined,
                country: cityAddress?.country,
                latitude: cityAddress.latitude,
                longitude: cityAddress.longitude
            }
            this.dialogRef.close({ address: this.address } as CustomAddressDialogResult);
        }
        else {
            this.formGroup.controls.cityAddress.markAsTouched();
            this.formGroup.controls.cityAddress.updateValueAndValidity()
        }
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}

export interface CustomAddressDialogResult {
    address: Address;
}
