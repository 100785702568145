import { PublicApplicationSettingsResponseDTO } from "projects/api-client/src/models/application/responses/PublicApplicationSettingsResponseDTO";

export class SmsOrderingService {

    public static readonly STEP = 100;
    public static readonly MAX = 10000;

    public additionalSmsNumber: number = 0;
    public smsUnitPriceExcludingTaxes: number = 0;

    constructor(
        private applicationSettings: PublicApplicationSettingsResponseDTO,
        public initialSmsNumber: number) {
        this.additionalSmsNumber = 0;
        this.smsUnitPriceExcludingTaxes = this.applicationSettings.payment.sms_unit_price_excluding_taxes;
    }

    public get totalSmsNumber(): number {
        return this.initialSmsNumber + this.additionalSmsNumber;
    }
    
    public set totalSmsNumber(value: number) {
        this.additionalSmsNumber = value - this.initialSmsNumber;
    }

    public get priceExcludingTaxes(): number {
        return this.smsToPay * this.smsUnitPriceExcludingTaxes;
    }

    public get smsToPay(): number {
        let smsToPay: number = this.additionalSmsNumber;
        if (smsToPay < 0) smsToPay = 0;
        return smsToPay;
    }
}