<mat-progress-bar class="qo-DataLoadingProgressBar fixed top-0 left-0 right-0 z-999" mode="indeterminate" *ngIf="LoadingKeys.DEFAULT.key|isLoading|async"></mat-progress-bar>
<div class="bg-black/60 fixed top-0 left-0 flex items-center justify-center h-full w-full z-9999 p-6" *ngIf="LoadingKeys.BLOCKING.key|isLoading|async">
    <div class="bg-white dark:bg-qo-dark-700 flex flex-col items-center justify-center py-10vh px-6 rounded-xl">
        <mat-spinner class="mb-8" diameter="100" color="primary" strokeWidth="6"></mat-spinner>
        <h1 class="qo-tiny-h1 mb-4 text-center" i18n>Processing</h1>
        <p class="m-0 text-center" i18n>Please wait while we set things up for you.</p>
    </div>
</div>

<div *ngIf="displayInformationMessage && !hideNotification" class="bg-white dark:bg-qo-dark-600 rounded-lg px-4 pt-4 pb-2 max-h-70vh overflow-auto flex items-start gap-3 fixed bottom-16 md:bottom-6 right-1/2 sm:right-6 translate-x-1/2 sm:translate-x-0 z-9999 max-w-sm w-full mat-elevation-z12">
    <mat-icon class="qo-PrimaryText">info</mat-icon>
    <div class="">
        <p class="font-medium mb-1">{{informationMessage}}</p>
        <a class="-ml-4" *ngIf="informationLink" [href]="informationLink" target="_blank" mat-button color="primary" i18n>Read more</a>
    </div>
    <button mat-icon-button class="-mt-2 -mx-2" (click)="closeInformationMessage()">
        <mat-icon>close</mat-icon> 
    </button>
</div>

<router-outlet></router-outlet>
