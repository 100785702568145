import { int } from '@zxing/library/esm/customTypings';
import { EventTeammateFieldType } from 'projects/api-client/src/models/common/EventTeammateFieldType';
import { LocalizedKeyString } from 'projects/api-client/src/models/common/LocalizedKeyString';
import { EventTeammateFieldDefinitionResponseDTO } from 'projects/api-client/src/models/event-teammate-field-definition/responses/EventTeammateFieldDefinitionResponseDTO';

export class TeammateFieldDefinition {
    id: string;
    type: EventTeammateFieldType;
    name: LocalizedKeyString;
    choices: LocalizedKeyString[] = [];
    template_id?: string;
    application_form_field_id?: string;
    position: int;
    is_public: boolean = false;
    created_at?: Date;
    updated_at?: Date;

    static from(dto: EventTeammateFieldDefinitionResponseDTO) : TeammateFieldDefinition {
        const teammateFieldDefinition = new TeammateFieldDefinition();        
        teammateFieldDefinition.id = dto.id;
        teammateFieldDefinition.type = dto.type;
        teammateFieldDefinition.name = dto.name;
        teammateFieldDefinition.choices = dto.choices;
        teammateFieldDefinition.template_id = dto.template_id;
        teammateFieldDefinition.application_form_field_id = dto.application_form_field_id;
        teammateFieldDefinition.position = dto.position;
        teammateFieldDefinition.is_public = dto.is_public;
        teammateFieldDefinition.created_at = dto.created_at ? new Date(dto.created_at):undefined;
        teammateFieldDefinition.updated_at = dto.updated_at ? new Date(dto.updated_at):undefined;
        return teammateFieldDefinition;
    }
}