
<div (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
    <input #searchInput matInput name="searchLocationCtrl" [readonly]="address != undefined" [placeholder]="placeholder" [formControl]="searchLocationCtrl" [matAutocomplete]="auto" [required]="required" autofill-disabled>

    <mat-autocomplete class="qo-LocationAutoComplete py-0" #auto="matAutocomplete" autoActiveFirstOption [displayWith]="getDisplayOption" (optionSelected)="setSelectedPrediction($event.option.value)" >
        <mat-option *ngFor="let prediction of locationPredictions" [value]="prediction" class="text-sm h-auto py-2 px-1 min-h-0 overflow-hidden">
            <div class="flex items-center overflow-hidden">
                <span class="material-symbols-outlined opacity-40 transform scale-75 m-0">place</span><span class="line-clamp-1" [innerHTML]="prediction.description | searchHighlight: searchLocationCtrl.value"></span>
            </div>   
        </mat-option>
        <span *ngIf="locationPredictions.length > 0" class="block p-1 text-xs text-right opacity-60 line-clamp-1" i18n>Powered by Google</span>
        <mat-option *ngIf="locationPredictions.length === 0" class="hidden"></mat-option>
        <div *ngIf="allowCustomAddress && showCustomAddressButton" class="text-sm py-1.5 px-2 border-t border-gray-200 dark:border-white/20">
            <button (click)="customAddressClicked.emit()" mat-stroked-button class="overflow-hidden text-sm-wl" i18n>Your address is not indicated?</button>
        </div>
    </mat-autocomplete>
</div>