import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationLicenseSettingsCommandDTO } from '../models/organization-settings-licence/commands/OrganizationLicenseSettingsCommandDTO';
import { OrganizationLicenseSettingsResponseDTO } from '../models/organization-settings-licence/reponses/OrganizationLicenseSettingsResponseDTO';
import { BaseAPIService } from './base.service';


@Injectable({
    providedIn: 'root'
})
export class OrganizationSettingsLicenseAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the organization's license settings.
     * @param id id of the organization
     */
     public get(id: string): Observable<OrganizationLicenseSettingsResponseDTO> {
        return this.httpGet<OrganizationLicenseSettingsResponseDTO>(`/organizations/${id}/settings/license`);
    }
    
    /**
     * Update the organization's license settings.
     * @param id id of the organization
     * @param body Command used to update the license
     */
    public update(id: string, body: OrganizationLicenseSettingsCommandDTO): Observable<OrganizationLicenseSettingsResponseDTO> {
        return this.httpPost<OrganizationLicenseSettingsResponseDTO>(`/organizations/${id}/settings/license`, body);
    }
}
