import { NgModule, ModuleWithProviders } from '@angular/core';
import { AccountAPIService } from './api/account.service';
import { ApiClientConfiguration, API_CLIENT_CONFIGURATION } from './configuration';
import { AssetAPIService } from './api/asset.service';
import { HealthAPIService } from './api/health.service';
import { OrganizationAPIService } from './api/organization.service';
import { EventAPIService } from './api/event.service';
import { EventTeammateAPIService } from './api/event-teammate.service';
import { ActivityAPIService } from './api/activity.service';
import { ShiftAPIService } from './api/shift.service';
import { AssignmentAPIService } from './api/assignment.service';
import { EventCategoryAPIService } from './api/event-category.service';
import { CouponAPIService } from './api/coupon.service';
import { NotificationAPIService } from './api/notification.service';
import { AdminEventAPIService } from './api/admin-event.service';
import { AdminOrganizationAPIService } from './api/admin-organization.service';
import { AdminUserAPIService } from './api/admin-user.service';
import { AdminPaymentAPIService } from './api/admin-payment.service';
import { EventMessageAPIService } from './api/event-message.service';
import { EventMembershipAPIService } from './api/event-membership.service';
import { ItemAPIService } from './api/item.service';
import { EventTeammateFieldDefinitionAPIService } from './api/event-teammate-field-definition.service';
import { AuthService } from './auth/auth.service';
import { BearerInterceptor } from './auth/bearer.interceptor';
import { ApiClientHttpClient, API_CLIENT_HTTP_INTERCEPTORS } from './http-client';
import { MealAPIService } from './api/meal.service';
import { EventSettingsTeammateProfileAPIService } from './api/event-settings-teammate-profile.service';
import { PointOfInterestAPIService } from './api/point-of-interest.service';
import { PreRegistrationAPIService } from './api/pre-registration.service';
import { OrganizationMembershipAPIService } from './api/organization-membership.service';
import { OrganizationTeammateFieldDefinitionTemplateAPIService } from './api/organization-teammate-field-definition-template.service';
import { OrganizationTeammateAPIService } from './api/organization-teammate.service';
import { OrganizationTeammateFieldDefinitionAPIService } from './api/organization-teammate-field-definition.service';
import { EventSettingsPublicationAPIService } from './api/event-publication.service';
import { EventSettingsApplicationAPIService } from './api/event-application.service';
import { EventSettingsLicenseAPIService } from './api/event-license.service';
import { EventSettingsMapAPIService } from './api/event-map.service';
import { EventSettingsMaterialAPIService } from './api/event-material.service';
import { EventSettingsMealsAPIService } from './api/event-meals.service';
import { OrganizationSettingsLicenseAPIService } from './api/organization-settings-license.service';
import { MessagingAPIService } from './api/messaging.service';
import { ApplicationSettingsAPIService } from './api/application-settings.service';
import { ServiceWorkerBypassInterceptor } from './auth/serviceWorkerBypass.interceptor';
import { WeezeventAPIService } from './api/weezevent.service';
import { EventServiceAPIService  } from './api/event-service.service';
import { EventPublisherAPIService } from './api/event-publisher.service';
import { AdminPublisherAPIService } from './api/admin-publisher.service';
import { AdminServiceAPIService } from './api/admin-service.service';
import { EventSettingsLocalizationAPIService } from './api/event-settings-localization.service';
import { EventLocalizationAPIService } from './api/event-localization.service';
import { AdminLocalizationAPIService } from './api/admin-localization.service';
import { OrganizationMessageAPIService } from './api/organization-message.service';

@NgModule({
    providers: [
        ApiClientHttpClient,
        AuthService,
        { 
            provide: API_CLIENT_HTTP_INTERCEPTORS, 
            useClass: BearerInterceptor,
            multi: true
        },
        { 
            provide: API_CLIENT_HTTP_INTERCEPTORS, 
            useClass: ServiceWorkerBypassInterceptor,
            multi: true
        },
        AccountAPIService,
        ActivityAPIService,
        AdminEventAPIService,
        AdminLocalizationAPIService,
        AdminOrganizationAPIService,
        AdminPaymentAPIService,
        AdminUserAPIService,
        AssetAPIService,
        AssignmentAPIService,
        MealAPIService,
        CouponAPIService,
        EventSettingsApplicationAPIService,
        EventCategoryAPIService,
        EventSettingsLicenseAPIService,
        EventSettingsMapAPIService,
        EventSettingsMaterialAPIService,
        EventSettingsMealsAPIService,
        EventLocalizationAPIService,
        EventMembershipAPIService,
        EventMessageAPIService,
        EventSettingsLocalizationAPIService,
        EventSettingsPublicationAPIService,
        EventSettingsTeammateProfileAPIService,
        EventAPIService,
        HealthAPIService,
        ItemAPIService,
        MessagingAPIService,
        NotificationAPIService,
        OrganizationAPIService,
        OrganizationMembershipAPIService,
        OrganizationMessageAPIService,
        OrganizationSettingsLicenseAPIService,
        OrganizationTeammateAPIService,
        OrganizationTeammateFieldDefinitionAPIService,
        ApplicationSettingsAPIService,
        PointOfInterestAPIService,
        PreRegistrationAPIService,
        ShiftAPIService,
        EventTeammateAPIService,
        EventTeammateFieldDefinitionAPIService,
        OrganizationTeammateFieldDefinitionTemplateAPIService,
        WeezeventAPIService,
        EventPublisherAPIService,
        EventServiceAPIService,
        AdminPublisherAPIService,
        AdminServiceAPIService
    ]
})
export class ApiCLientModule {
    public static forRoot(): ModuleWithProviders<ApiCLientModule> {
        return {
            ngModule: ApiCLientModule,
            providers: [{ provide: API_CLIENT_CONFIGURATION, useValue: new ApiClientConfiguration() }]
        };
    }
}
