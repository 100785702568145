<h1  class="qo-h2 mb-8 text-center" i18n>Enter your payment information</h1>
<div class="flex flex-col mx-auto max-w-xl md:flex-row md:justify-between md:mt-12 md:max-w-6xl">
    <div class="md:mt-10 md:pt-6 md:pr-8 md:w-1/2">
        <p class="text-2xl/tight font-medium mb-6 md:mb-10 text-left">
            <span class="text-xl">{{quantityLabel}}</span><br />
            {{quantity}}
        </p>
        <p class="pt-2 border-t border-qo-light text-2xl/tight font-medium mb-6 md:mb-10 text-left">
            <span class="text-xl" i18n>Total</span><br />
            <ng-container *ngIf="priceIncludingTaxes == priceExcludingTaxes">
                <span class="inline-flex items-center text-5xl md:text-6xl">{{priceExcludingTaxes|currencyNoZeroCents:Currency.EUR}}<span class="text-xl ml-1" i18n> excl tax</span></span>
            </ng-container>
            <ng-container *ngIf="priceIncludingTaxes != priceExcludingTaxes">
                <span class="inline-flex items-center text-5xl md:text-6xl">{{priceIncludingTaxes|currencyNoZeroCents:Currency.EUR}}<span class="text-xl ml-1" i18n> incl tax</span></span><br />
                <span class="inline-flex items-center text-2xl opacity-40">{{priceExcludingTaxes|currencyNoZeroCents:Currency.EUR}}<span class="text-sm ml-1" i18n> excl tax</span></span>
            </ng-container>            
        </p>
    </div>
    <div class="border border-qo-light rounded-xl p-4 md:py-6 md:px-8 md:w-1/2">
        <h2 class="qo-h3 mb-1 text-center" i18n>Secure payment by credit card</h2>
        <p class="text-center"><img class="inline" src="assets/images/cb-logo.jpg" alt="" /></p>
        <div id="lyraEmbeddedForm"></div>
        <mat-error>{{ error }}</mat-error>
    </div>    
</div>