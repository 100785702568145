import { Component, OnInit, Input, Inject, LOCALE_ID, Output, EventEmitter, NgZone } from '@angular/core';
import KRGlue from "@lyracom/embedded-form-glue";
import { Currency } from '../../models/common/currency';
import { ApplicationSettingsService } from '../../services/application-settings-service/application-settings.service';

@Component({
    selector: 'payment-form',
    templateUrl: './payment-form.component.html',
    styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {

    Currency = Currency;
    error?: string;
    KR: KR;

    @Input() paymentFormToken?: string;
    @Input() quantity: number;
    @Input() quantityLabel: string = $localize`Quantity`;
    @Input() priceIncludingTaxes: number;
    @Input() priceExcludingTaxes: number;

    @Output() paymentSucceeded = new EventEmitter<void>();
    @Output() paymentFailed = new EventEmitter<void>();

    constructor(@Inject(LOCALE_ID) public locale: string,
        private applicationSettingsService: ApplicationSettingsService,
        private ngZone: NgZone) {
    }

    async ngOnInit() {

        this.KR = (await KRGlue.loadLibrary("https://api.lyra.com", this.applicationSettingsService.settings.payment.payzen_public_client_key!)).KR;

        await this.KR.setFormConfig({
            formToken: this.paymentFormToken,
            "kr-language": this.locale
        });

        const formId = (await this.KR.addForm("#lyraEmbeddedForm")).result.formId;

        this.KR.onSubmit(response => {
            this.ngZone.run(() => {
                if (response.clientAnswer.orderStatus === "PAID") {
                    this.paymentSucceeded.emit();
                    this.KR.removeForms();
                }
            });
            return true;
        });

        this.KR.onError(response => {
            this.ngZone.run(() => {
                // After too many attempts
                if (response.errorCode === "PSP_099") {
                    this.paymentFailed.emit();                    
                    this.KR.removeForms();                    
                }
            });
            return this.KR;
        })

        try {
            this.KR.showForm(formId);
        }
        catch (error) {
            this.error = error;
        };
    }
}