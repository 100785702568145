<form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="save()">
    <div mat-dialog-title i18n>Enter your address</div>
    <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
      
        <mat-form-field appearance="fill" class="w-full">
            <mat-label i18n>Address line 1</mat-label>
            <input cdkFocusInitial matInput type="text" formControlName="line1" autocomplete="address-line1"  id="address-line1">
        </mat-form-field>
        <br />
        <mat-form-field appearance="fill" class="w-full">
            <mat-label i18n>Address line 2</mat-label>
            <input matInput type="text" formControlName="line2" autocomplete="address-line2" id="address-line2">
        </mat-form-field>
        <br /> 
        <mat-form-field appearance="fill" class="w-full">
            <mat-label i18n>City</mat-label>
            <address-input #city class="w-full" formControlName="cityAddress" 
                [placeSearchScope]="PLACE_SEARCH_SCOPE.CITIES" required>    
            </address-input>
            <mat-icon *ngIf="!formGroup.value.cityAddress" matSuffix>search</mat-icon>
            <button mat-icon-button *ngIf="formGroup.value.cityAddress" matSuffix (click)="clearCirtyAddress()">
                <mat-icon class="material-icons-outlined" matTooltip="Delete city" i18n-matTooltip>clear</mat-icon>
            </button>
            <mat-error *ngIf="formGroup.controls.cityAddress?.errors && formGroup.controls.cityAddress?.errors.required" i18n>You must provide a valid city</mat-error>
        </mat-form-field>
        
        <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field appearance="fill" class="w-full">
                <mat-label i18n>Postal code</mat-label>
                <input matInput type="text" formControlName="postalCode" autofill-disabled id="address-postalCode">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-full">
                <mat-label i18n>Region / State</mat-label>
                <input matInput type="text" formControlName="state" autofill-disabled id="address-state">
            </mat-form-field>
        </div>
        <mat-form-field appearance="fill" class="w-full qo-readonly">
            <mat-label i18n>Country</mat-label>
            <input readonly matInput readonly type="text" formControlName="country" autofill-disabled id="address-country">
            <mat-icon matSuffix>lock</mat-icon>
        </mat-form-field>       
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button color="primary" type="submit" i18n>Save</button>
        <button mat-button [mat-dialog-close] i18n>Cancel</button>
    </div>
</form>