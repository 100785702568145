import { isPlatformBrowser } from "@angular/common";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";

declare var window: {
    [key: string]: any;
    prototype: Window;
    new(): Window;
};

@Injectable({
    providedIn: 'root',
})
export class MatomoService {

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        if (isPlatformBrowser(this.platformId)) {
            window._paq = window._paq || [];
        }
    }

    init(url: string, id: number) {
        if (isPlatformBrowser(this.platformId)) {
            window._paq.push(['enableCrossDomainLinking']);            
            window._paq.push(['disableCookies']);
            window._paq.push(['trackPageView']);
            window._paq.push(['enableLinkTracking']);
            (() => {
                window._paq.push(['setTrackerUrl', `${url}/matomo.php`]);
                window._paq.push(['setSiteId', id.toString()]);
                const d = document;
                const g = d.createElement('script');
                const s: HTMLScriptElement = d.getElementsByTagName('script')[0];
                g.type = 'text/javascript';
                g.async = true;
                g.defer = true;
                g.src = `${url}/matomo.js`;
                s.parentNode!.insertBefore(g, s);
            })();
        }
    }

    private get initialized(): boolean {
        return (typeof window !== 'undefined' && typeof window!._paq !== 'undefined');
    }

    private async isTrackingEnabled(): Promise<boolean> {
        return this.initialized && await this.getUserTrackingOptOutStatus() === false;
    }

    async toggleTrackingOptOutStatus() {
        if (this.initialized) {
            return new Promise((resolve, reject) => {
                window._paq.push([
                    function (this: any) {
                        if (this.isUserOptedOut()) {
                            // Forget optOut, tracking is enabled
                            window._paq.push(['forgetUserOptOut']);
                            resolve(this.isUserOptedOut());
                        } else {
                            // set optOut, tracking is disabled
                            window._paq.push(['optUserOut']);
                        }
                    }
                ]);
            });
        }
        return false;
    }

    async getUserTrackingOptOutStatus(): Promise<boolean> {
        if (this.initialized) {
            return new Promise((resolve, reject) => {
                try {
                    window._paq.push([
                        function (this: any) {
                            resolve(this.isUserOptedOut());
                        }
                    ]);
                } catch (e) {
                    if (!(e instanceof ReferenceError)) {
                        resolve(false);
                    }
                }
            });

        }
        return false;
    }

    async trackPageView(customTitle?: string) {
        if (await this.isTrackingEnabled()) {
            try {
                const args: any[] = [];
                if (!!customTitle) {
                    args.push(customTitle);
                }
                window._paq.push(['setCustomUrl', window.location.href]);
                window._paq.push(['trackPageView', ...args]);
            } catch (e) {
                if (!(e instanceof ReferenceError)) {
                    throw e;
                }
            }
        }
    }

    async trackEvent(category: string, action: string, name?: string, value?: number) {
        if (await this.isTrackingEnabled()) {
            try {
                const args: any[] = [category, action];
                if (!!name) {
                    args.push(name);
                }
                if (typeof value === 'number') {
                    args.push(value);
                }
                window._paq.push(['trackEvent', ...args]);
            } catch (e) {
                if (!(e instanceof ReferenceError)) {
                    throw e;
                }
            }
        }
    }

    async trackSiteSearch(keyword: string, category: string, resultsCount?: number) {
        if (await this.isTrackingEnabled()) {
            try {
                const args: any[] = [keyword];
                args.push(category);
                if (typeof resultsCount === 'number') {
                    args.push(resultsCount);
                }
                window._paq.push(['trackSiteSearch', ...args]);
            } catch (e) {
                if (!(e instanceof ReferenceError)) {
                    throw e;
                }
            }
        }
    }
}